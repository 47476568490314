import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useCheckout } from 'src/public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { useDelivery } from 'src/public/components/online_ordering/DeliveryContext';
import InputField from 'src/shared/components/common/form_input/InputField';
import ToggleInput from 'src/shared/components/common/forms/ToggleInput';

import AnimatedSection from './AnimatedSection';

const DeliveryPreferencesSection = () => {
  const { saveNewAddress, setSaveNewAddress, contactlessDelivery, setContactlessDelivery } = useCheckout();
  const { customer } = useCustomer();
  const { setFocus, resetField, setValue } = useFormContext();
  const { cart } = useCart();
  const { savedAddressUsed } = useDelivery();

  useEffect(() => {
    if(cart?.diningOptionBehavior !== DiningOptionBehavior.Delivery) {
      setSaveNewAddress(false);
      setContactlessDelivery(false);
    }
    setValue('deliveryInstructions', cart?.order?.deliveryInfo?.notes);
  }, [setValue, setSaveNewAddress, cart, setContactlessDelivery]);

  useEffect(() => {
    if(saveNewAddress) {
      // forcing the focus here makes sure the validation for this field gets run
      setFocus('addressLabel');
    } else {
      // reset field to ensure form reevaluates validity.
      // https://react-hook-form.com/docs/useform/resetfield
      resetField('addressLabel');
    }
  }, [saveNewAddress, setFocus, resetField]);

  if(cart?.diningOptionBehavior !== DiningOptionBehavior.Delivery) {
    return null;
  }

  const showSaveAddress = !!(customer && !savedAddressUsed);

  return (
    <>
      <InputField id="deliveryInstructions" type="text" label="Delivery Instructions (e.g. please ring doorbell, enter code...)" preventFormSubmit />
      <div className="contactlessCheckbox" data-testid="contactless-delivery">
        <ToggleInput id="contactlessDelivery" type="checkbox" name="addContactlessDelivery" checked={contactlessDelivery}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContactlessDelivery(event.target.checked)} >
          Leave it at my door.
        </ToggleInput>
      </div>
      {showSaveAddress &&
        <div className="inputField saveAddrCheckbox"
          data-testid="save-new-address">
          <ToggleInput
            type="checkbox"
            name="saveNewAddress"
            id="saveNewAddress"
            checked={saveNewAddress}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setSaveNewAddress(event.target.checked); }}>
                Save address
          </ToggleInput>
        </div>}
      <AnimatedSection expanded={saveNewAddress}>
        <div className="addressLabel">
          <InputField id="addressLabel" type="text" label="Address label" defaultValue="" required={saveNewAddress} preventFormSubmit />
        </div>
      </AnimatedSection>
    </>
  );
};

export default DeliveryPreferencesSection;
